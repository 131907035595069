<template>
  <v-container
    fluid
    id="docForm"
  >
    <v-row dense>

      <!-- doc num -->
      <v-col cols="2">
        <v-autocomplete
          outlined
          dense
          v-model="Series"
          :items="seriesData"
          item-text="SeriesName"
          item-value="id"
          label="Series"
          @change="setDocNumber(Series)"
        ></v-autocomplete>
      </v-col>

      <v-col cols="2">
        <v-text-field
          outlined
          dense
          label="No."
          v-model="record.DocNum"
        ></v-text-field>
      </v-col>
      <!-- end -->

      <v-col cols="4">
        <DatePicker
          @date="setPostingDate"
          :myDate="record.DocDate"
          :title="`Posting Date`"
        ></DatePicker>
      </v-col>

      <v-col cols="4">
        <DatePicker
          @date="setDeliveryDate"
          :myDate="record.DocDueDate"
          :title="`Due Date`"
        ></DatePicker>
      </v-col>

      <v-col cols="4">
        <DatePicker
          @date="setDocumentDate"
          :myDate="record.TaxDate"
          :title="`Document Date`"
        ></DatePicker>
      </v-col>

      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Reference"
          v-model="record.CounterRef"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>

      <v-row>
        <v-container fluid>

          <!-- start of items table -->
          <v-data-table
            hide-default-footer
            dense
            v-model="selectedDocs"
            show-select
            @item-selected="clickedDocs"
            item-key="id"
            :headers="itemHeaders"
            :items="selectedAccounts"
          >

            <template v-slot:item.AcctCode="{ item }">
              <v-autocomplete
                name="posProductName"
                style="width: 250px"
                autocomplete="off"
                dense
                outlined
                single-line
                v-model="item.AcctCode"
                :items="chartOfAccounts"
                label="Select G/L Account"
                :item-text="item => item.AcctCode +'  -  '+ item.AcctName"
                item-value="id"
              ></v-autocomplete>
            </template>

            <template v-slot:item.AcctName="{ item }">
              <v-text-field
                single-line
                outlined
                dense
              ></v-text-field>
            </template>
            <template v-slot:item.VatGroup="{ item }">
              <v-autocomplete
                autocomplete="off"
                dense
                outlined
                single-line
                v-model="item.id"
                :items="taxGroups"
                label="Tax Code"
                item-text="code"
                item-value="id"
              ></v-autocomplete>
            </template>

            <!-- <template v-slot:item.SumApplied="{ item }">
              <v-text-field
                @input="inputChanged(item)"
                single-line
                outlined
                dense
                v-model="item.Price"
              ></v-text-field>
            </template> -->

            <!-- start of doc date template -->
            <template v-slot:item.DocDate="{ item }">{{ item.DocDate | moment("Do MMM YYYY")}}</template>
            <!-- end of doc date template -->

            <!-- start of doc total template -->
            <template v-slot:item.DocTotal="{ item }">{{ item.DocTotal.toLocaleString() }}</template>
            <!-- end of doc total template -->

            <!-- start of balance template -->
            <template v-slot:item.balance="{ item }">{{ item.balance.toLocaleString() }}</template>
            <!-- end of balance template -->

            <!-- total payment template -->
            <template v-slot:item.SumApplied="props">
              <v-text-field
                outlined
                dense
                v-model="props.item.SumApplied"
                @input="inputChanged(props.item)"
                single-line
              ></v-text-field>

            </template>
            <!-- end of total payment template -->

            <!-- Cost Center Code -->
            <template v-slot:item.OcrCode="{ item }">

              <!-- {{distributionRules1}} -->
              <v-autocomplete
                single-line
                dense
                outlined
                v-model="item.OcrCode"
                :items="distributionRules1"
                item-text="OcrCode"
                item-value="id"
              ></v-autocomplete>
            </template>
            <!-- end -->

            <!-- Cost Center Code -->
            <template v-slot:item.OcrCode2="{ item }">
              <v-autocomplete
                single-line
                dense
                outlined
                v-model="item.OcrCode2"
                :items="distributionRules2"
                item-text="OcrCode"
                item-value="id"
              ></v-autocomplete>
            </template>
            <!-- end -->

            <!-- Cost Center Code -->
            <template v-slot:item.OcrCode3="{ item }">
              <v-autocomplete
                single-line
                dense
                outlined
                v-model="item.OcrCode3"
                :items="distributionRules3"
                item-text="OcrCode"
                item-value="id"
              ></v-autocomplete>
            </template>
            <!-- end -->

            <!-- Cost Center Code -->
            <template v-slot:item.OcrCode4="{ item }">
              <v-autocomplete
                single-line
                dense
                outlined
                v-model="item.OcrCode4"
                :items="distributionRules4"
                item-text="OcrCode"
                item-value="id"
              ></v-autocomplete>
            </template>
            <!-- end -->

            <!-- Cost Center Code -->
            <template v-slot:item.OcrCode5="{ item }">
              <v-autocomplete
                single-line
                dense
                outlined
                v-model="item.OcrCode5"
                :items="distributionRules5"
                item-text="OcrCode"
                item-value="id"
              ></v-autocomplete>
            </template>
            <!-- end -->
          </v-data-table>
          <!-- end of items table -->

          <v-btn
            @click="addRow"
            small
            color="primary"
            class="mt-1"
          >
            <v-icon left>mdi-plus</v-icon>Add Row
          </v-btn>

        </v-container>
      </v-row>

      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>

      <v-col cols="7">
        <v-row>
          <v-col cols="6">
            <v-textarea
              outlined
              v-model="record.Comments"
              label="Remarks"
              document_lines="1"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-textarea
              outlined
              v-model="record.JrnlMemo"
              label="Journal Remarks"
              document_lines="1"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              outlined
              dense
              readonly
              label="External Refrence"
              v-model="record.ExtRefDocNum"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="5">
        <v-row dense>

          <v-col cols="6">
            <v-text-field
              dense
              outlined
              label="Net Total"
              v-model="paymentNetTotal"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-btn
              icon
              @click="paymentDialog = true"
            >
              <v-icon color="red">mdi-sack-percent</v-icon>
            </v-btn>
          </v-col>

        </v-row>
        <v-row dense>

          <v-col cols="6">
            <v-text-field
              dense
              outlined
              label="Total Tax"
              v-model="paymentTax"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>

          <v-col cols="6">
            <v-text-field
              dense
              outlined
              label="Total Amount Due"
              v-model="TotalAmountDue"
            ></v-text-field>
          </v-col>
        </v-row>

      </v-col>
      <!-- selected items list -->
    </v-row>

    <v-row>
      <v-col cols="10">
        <v-btn
          color="accent"
          @click="sendData"
        >
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>

    <!-- start of payment means modal -->
    <v-dialog
      v-model="paymentDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="accent"
        >
          <v-toolbar-title>
            <v-btn
              icon
              dark
              @click="paymentDialog = false"
            >
              <v-icon dark>mdi-keyboard-backspace</v-icon>
            </v-btn>Payment Means
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="paymentDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-container>
          <v-row
            align="center"
            justify="center"
          >
            <v-col cols="8">
              <v-tabs color="white">
                <v-tab>Cheque</v-tab>

                <v-tab>Cash</v-tab>

                <v-tab-item>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        label="Currency"
                        value="KES"
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col colls="8">
                      <!-- cheque dialog -->
                      <cheque-editor @chequeData="addCheque"></cheque-editor>
                      <!-- end -->
                    </v-col>
                    <v-col cols="12">
                      <!-- cheques table -->
                      <v-data-table
                        :headers="chequeHeaders"
                        :items="cheques"
                      ></v-data-table>
                      <!-- end of cheques table -->
                      <label>Cheques Total:</label>
                      {{CheckSum.toLocaleString()}}
                    </v-col>
                  </v-row>
                </v-tab-item>

                <!-- start of cash -->
                <v-tab-item>
                  <v-col cols="4">
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          name="posProductName"
                          style="width: 250px"
                          autocomplete="off"
                          dense
                          outlined
                          single-line
                          v-model="record.CashAcct"
                          :items="chartOfAccounts"
                          label="Select G/L Account"
                          :item-text="item => item.AcctCode +'  -  '+ item.AcctName"
                          item-value="id"
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          label="Amount"
                          v-model="CashSum"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-tab-item>
                <!-- end of cash -->
              </v-tabs>
            </v-col>

            <!-- start of footer -->
            <v-col cols="8">
              <v-divider></v-divider>
            </v-col>

            <v-col cols="8">
              <v-row>
                <!--  -->
                <v-col cols="8">
                  <v-row>
                    <v-col cols="6">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            label="Overall Amount"
                            v-model="TotalAmountDue"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            label="Balance Due"
                            v-model="TotalBalance"
                            readonly
                            filled
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field label="Bank Charge"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <!--  -->
                <v-col cols="4">
                  <v-text-field
                    label="Paid"
                    v-model="TotalPaid"
                  ></v-text-field>
                </v-col>
                <!--  -->
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-btn color="primary">
                    <v-icon left>mdi-plus</v-icon>Add
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- end of payment means mpdal -->

    <!-- start of bp fullscreen modal -->
    <v-dialog
      v-model="bpDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="accent"
        >
          <v-toolbar-title>
            <v-btn
              icon
              dark
              @click="bpDialog = false"
            >
              <v-icon dark>mdi-keyboard-backspace</v-icon>
            </v-btn>Select Customer
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="bpDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  <v-text-field
                    hide-details
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  show-select
                  @item-selected="clicked"
                  item-key="id"
                  :items-per-page="itemsPerPage"
                  v-model="selected"
                  :headers="bpHeaders"
                  :items="bpMasterData"
                  :single-select="singleSelect"
                  class="elevation-1"
                  :search="search"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <!-- snackbar -->
      <snackbar ref="snackbar"></snackbar>
      <!-- end of snack-->
    </v-dialog>
    <!-- end of bp modal-->
  </v-container>
</template>


<script>
import ChequeEditor from "./cheque-dialog.vue";
export default {
  components: {
    ChequeEditor,
  },
  props: {
    tenantData: {
      type: Object,
    },
    initial: {
      type: Object,
      required: true,
    },
    editMode: {
      type: Boolean,
    },
    currencies: {
      type: Array,
    },
  },
  computed: {
    saleItemsData() {
      return this.record.inv1;
    },
    TotalPaid() {
      const total =
        this.CheckSum + parseFloat(this.TrsfrSum) + parseFloat(this.CashSum);
      return total;
    },
    TotalBalance() {
      const balance = this.TotalAmountDue - this.TotalPaid;
      return balance;
    },
    TotalAmountDue() {
      let total = 0;
      if (
        typeof this.selectedAccounts != "undefined" &&
        this.selectedAccounts.length > 0
      ) {
        return this.selectedAccounts
          .map((item) => {
            const amount = item.SumApplied;
            total = +amount;
            return total;
          })
          .reduce((val, a) => {
            return val + a;
          });
      } else {
        return total;
      }
    },
    CheckSum() {
      let total = 0;
      if (typeof this.cheques != "undefined" && this.cheques.length > 0) {
        return this.cheques
          .map((item) => {
            const amount = item.CheckSum;
            total = +amount;
            return total;
          })
          .reduce((val, a) => {
            return val + a;
          });
      } else {
        return total;
      }
    },
  },
  data: () => ({
    search: null,
    paymentDialog: false,
    singleSelect: true,
    paymentAccount: false,
    netTotal: 0,
    CashSum: 0,
    TrsfrSum: 0,
    AmountDue: null,
    OpenBalance: null,
    Status: "open",
    CardType: "C",
    selected: [],
    selectedItems: [],
    selectedDocs: [],

    selectedTenant: {},
    record: { parent: false, vpm4: [], vpm1: [] },
    itemsPerPage: 20,
    cheques: [],
    SlpCode: null,
    loading: false,
    draftDialog: false,
    bpDialog: false,
    itemDialog: false,
    trueValue: true,
    tab: null,
    date: null,
    DocDate: null,
    TaxDate: null,
    DocDueDate: null,
    priceList: [],
    salesEmployees: [],
    employees: [],
    bpMasterData: [],
    itemGroups: [],
    selectedAccounts: [],
    chartOfAccounts: [],
    distributionRules1: [],
    distributionRules2: [],
    distributionRules3: [],
    distributionRules4: [],
    distributionRules5: [],
    defaultSeries: [],
    taxGroups: [],
    seriesData: [],
    paymentAccountAmount: "",
    paymentNetTotal: "",
    paymentTax: "",
    ExtRef: "",
    CashAcct: "",
    Series: "",
    bpHeaders: [
      { text: "Code", value: "CardCode" },
      { text: "Name", value: "CardName" },
      { text: "Foreign Name", value: "CardFName" },
      { text: "Group", value: "GroupCode" },
    ],
    itemHeaders: [
      { text: "G/L Account", value: "AcctCode" },
      { text: "Tax", value: "VatGroup" },
      { text: "Net Amount", value: "SumApplied" },
      { text: "Location", value: "OcrCode" },
      { text: "Department", value: "OcrCode2" },
      { text: "Product Line", value: "OcrCode3" },
    ],

    chequeHeaders: [
      { text: "Due Date", value: "DueDate" },
      { text: "Amount", value: "CheckSum" },
      { text: "Country", value: "CountryCod" },
      { text: "Bank Name", value: "BankCode" },
      { text: "Branch", value: "Branch" },
      { text: "Account", value: "AcctNum" },
      { text: "Cheque No.", value: "CheckNum" },
      { text: "Endorse", value: "Endorse" },
    ],
    groups: [],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
    tenantData: {
      handler: "setTenant",
      immediate: true,
    },
    defaultSeries: {
      handler: "setSeries",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
      this.paymentNetTotal = val.DocTotal;
      this.CashSum = val.CashSum;

      const products = [];
      if (val.vpm4) {
        for (const row of val.vpm4) {
          products.push(row);
        }
        this.selectedAccounts = products;
      }

      const checks = [];
      if (val.vpm1) {
        for (const check of val.vpm1) {
          checks.push(check);
        }
        this.cheques = checks;

        console.log(this.cheques, "Checks");
      }
    },
    setTenant(val) {
      this.selectedTenant = { ...val };
    },
    openDraftDialog() {
      this.draftDialog = true;
    },
    addItemData(data) {
      this.record.inv1.push(data);
    },
    clickedDocs($event) {
      console.log($event, "event");
    },
    addCheque(data) {
      this.cheques.push(data);
    },
    clicked($event) {
      this.selectedTenant = $event.item;
      const tenant = $event.item;
      this.record.CardCode = tenant.id;
      this.record.NumAtCard = tenant.NumAtCard;
      this.record.CardName = tenant.CardName;
      // get documents
      const CustomerId = tenant.id;
      const ObjType = 13;
      const self = this;
      self.loading = true;
      this.$store
        .dispatch("get", `/customer/${CustomerId}/${ObjType}`)
        .then((res) => {
          const docs = res;
          const docsData = [];
          docs.forEach((doc) => {
            const balance = doc.DocTotal - doc.PaidToDate;
            console.log(balance, "paid");
            doc.balance = balance;
            doc.SumApplied = balance;
            docsData.push(doc);
          });
          self.saleDocs = docsData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
      // end
      this.bpDialog = false;
    },
    setTransferDate(date) {
      this.record.TrsfrDate = date;
    },
    setPostingDate(date) {
      this.record.DocDate = date;
    },
    setDeliveryDate(date) {
      this.record.DocDueDate = date;
    },
    setDocumentDate(date) {
      this.record.TaxDate = date;
    },
    sendData() {
      this.record.Series = this.Series;
      this.record.DocTotal = this.TotalAmountDue;
      this.record.CheckSum = this.CheckSum;
      this.record.CashSum = this.CashSum;
      this.record.TrsfrSum = this.TrsfrSum;
      this.record.DocType = "A";
      this.record.rct2 = this.selectedDocs;
      this.record.BaseEntry = localStorage.BaseDocID;
      this.record.BaseType = 205;
      this.record.vpm1 = this.cheques;
      this.record.vpm4 = this.selectedAccounts;
      this.$emit("data", this.record);
    },
    openBp() {
      this.selected = [];
      this.bpDialog = true;
    },
    getBpMaster() {
      const self = this;
      self.loading = true;
      this.$store
        .dispatch("get", `/bp_masterdata`)
        .then((res) => {
          self.bpMasterData = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    setDefaults() {
      // date defaults
      const date = new Date().toISOString().substr(0, 10);
      this.record.DocDate = date;
      this.record.TaxDate = date;
      this.record.DocDueDate = date;
      // Currency source

      this.record.CurSource = "L";
      this.record.Series = "M";
      this.paymentNetTotal = localStorage.BaseDocTotal;
      this.paymentAccountAmount = localStorage.BaseDocTotal;
      this.DocTotal = localStorage.BaseDocTotal;
    },
    save() {
      this.$refs.snackbar.show("Data saved", "green");
    },
    cancel() {
      this.$refs.snackbar.show("Cancelled", "green");
    },
    open() {},
    close() {
      console.log("Dialog closed");
    },
    addRow() {
      let initArray = [];
      const LineNum = new Date().getTime();
      const details = {
        LineNum: LineNum,
        AcctCode: null,
        AcctName: null,
        VatGroup: null,
        SumApplied: null,
        OcrCode: null,
        OcrCode2: null,
        OcrCode3: null,
      };
      initArray.push(details);
      this.selectedAccounts = [...this.selectedAccounts, ...initArray];
    },
    setDefaultRow() {
      const LineNum = new Date().getTime();
      let initArray = [];
      const details = {
        LineNum: LineNum,
        AcctCode: null,
        AcctName: null,
        VatGroup: null,
        SumApplied: localStorage.BaseDocTotal,
        OcrCode: null,
        OcrCode2: null,
        OcrCode3: null,
      };
      initArray.push(details);
      this.selectedAccounts = [...this.selectedAccounts, ...initArray];
    },

    getChartOfAccounts() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/activeGLaccounts`)
        .then((res) => {
          self.chartOfAccounts = res;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getDistributionRules(id) {
      const self = this;
      this.$store
        .dispatch("get", `/distribution-rules/${id}`)
        .then((res) => {
          if (id == 1) {
            self.distributionRules1 = res.ResponseData;
          }
          if (id == 2) {
            self.distributionRules2 = res.ResponseData;
          }
          if (id == 3) {
            self.distributionRules3 = res.ResponseData;
          }
          if (id == 4) {
            self.distributionRules4 = res.ResponseData;
          }
          if (id == 5) {
            self.distributionRules5 = res.ResponseData;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTaxGroups() {
      const self = this;
      this.$store
        .dispatch("get", `/taxgroups/input`)
        .then((res) => {
          self.taxGroups = res;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    inputChanged(data) {
      this.paymentNetTotal = data.SumApplied;
    },
    formSettings() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/form_settings/46`)
        .then((res) => {
          self.seriesData = res.Series;
          self.defaultSeries = res.DfltSeries;
        })
        .catch((err) => {
          console.log(err, "error");
        });
      // end
    },
    setDocNumber(id) {
      const seriesRecord = this.seriesData.find((record) => {
        return record.id === id;
      });

      if (seriesRecord.IsManual === "Y") {
        this.docReadonly = "N";
        this.record = { ...this.record, DocNum: null };
      } else {
        this.docReadonly = "Y";
        this.record = { ...this.record, DocNum: seriesRecord.NextNumber };
      }
      this.activeSeries = seriesRecord.id;
    },

    setSeries(val) {
      if (val) {
        if (val.NextNumber) {
          this.record.DocNum = val.NextNumber;
        }

        if (val.IsManual == "Y") {
          this.docReadonly = "N";
        } else {
          this.docReadonly = "Y";
        }

        this.activeSeries = val.id;
        this.Series = val.id;
      }
    },
  },
  created() {
    this.setDefaults();
    this.setDefaultRow();
    this.getBpMaster();
    this.getTaxGroups();
    this.formSettings();
    this.getChartOfAccounts();
    this.getDistributionRules(1);
    this.getDistributionRules(2);
    this.getDistributionRules(3);
    this.getDistributionRules(4);
    this.getDistributionRules(5);
  },
};
</script>

