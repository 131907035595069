<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-btn color="primary" small dark @click="openDialog" right absolute>
          <v-icon left>mdi-plus</v-icon>Cheque
        </v-btn>
      </v-col>
    </v-row>
    
    <!-- dialog -->
    <v-dialog v-model="dialog" width="1000px">
      <v-card>
        <v-toolbar color="secondary" dense flat>
          <v-toolbar-title>New Cheque</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row dense>
            <v-col cols="4">
              <DatePicker @date="setDueDate" :myDate="record.DueDate" :title="`Due Date`"></DatePicker>
            </v-col>
            <v-col cols="4">
              <v-text-field outlined dense label="Amount" v-model="record.CheckSum"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                outlined 
                dense
                v-model="record.CountryCod"
                :items="countries"
                item-text="name"
                item-value="value"
                label="Country"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                outlined
                dense
                v-model="record.BankCode"
                :items="banks"
                item-text="name"
                item-value="value"
                label="Bank Name"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-text-field outlined dense label="Branch" v-model="record.Branch"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field outlined dense label="Account" v-model="record.AcctNum"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field outlined dense label="Cheque No." v-model="record.CheckNum"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field outlined dense label="Endorse" v-model="record.Endorse"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-btn color="accent" @click="sendData">
                <v-icon left>mdi-plus</v-icon>Add
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      record: {},
      countries: [
        { name: "Kenya", value: "Kenya" },
        { name: "Rwanda", value: "Rwanda" }
      ],
      banks: [
          { name: 'KCB Bank', value: 'KCB Bank' },
          { name: 'Equity Bank', value: 'Equity Bank' }
      ]
    };
  },
  methods: {
    openDialog() {
      this.record = {};
      this.dialog = true;
    },
    setDueDate(date) {
      this.record.DueDate = date;
    },
    sendData() {
        const details = this.record;
        this.$emit('chequeData', details);
        this.dialog = false;
    }
  }
};
</script>

<style scoped>
</style>